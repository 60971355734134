// Permissions helper functions
//
import Vue from 'vue'
import store from '../store'

// Profiles
//
const PROFILE_MASTER_ADMIN = 'profile_master_admin'
const PROFILE_SUPPORT_ADMIN = 'profile_support_admin'
const PROFILE_PACS_ADMIN = 'profile_pacs_admin'
const PROFILE_RAD1 = 'profile_radiologist1'
const PROFILE_RAD2 = 'profile_radiologist2'
const PROFILE_QA = 'profile_quality_assurance'
const PROFILE_TECH = 'profile_technologist'
const PROFILE_RECEPTION = 'profile_reception'
const PROFILE_TRANSCRIPTION = 'profile_transcription'
const PROFILE_NURSE = 'profile_nurse'

// Permissions
//
const CAN_ASSIGN_STUDIES = 'can_assign_studies'
const CAN_DO_QUERY_SEARCH = 'can_do_query_search'
const CAN_DOWNLOAD_REPORT = 'can_download_report'
const CAN_DOWNLOAD_STUDY = 'can_download_study'
const CAN_EDIT_NOTES = 'can_edit_notes'
const CAN_EDIT_PATIENT_HISTORY = 'can_edit_patient_history'
const CAN_EDIT_PI = 'can_edit_pi'
const CAN_EDIT_REPORTS = 'can_edit_reports'
const CAN_LOCK_STUDY = 'can_lock_study'
const CAN_MARK_STAT = 'can_mark_stat'
const CAN_ROUTE_STUDY = 'can_route_study'
const CAN_SIGN_FINAL = 'can_sign_final'
const CAN_SIGN_PRELIM = 'can_sign_prelim'
const CAN_SHARE_STUDY = 'can_share_study'
const CAN_UPDATE_STUDY = 'can_update_study'
const CAN_UPDATE_STUDY_TENANT = 'can_update_study_tenant'
const CAN_UPLOAD_STUDY = 'can_upload_study'
const CAN_VIEW_IMAGES = 'can_view_images'
const CAN_VIEW_PATIENT_HISTORY = 'can_view_patient_history'
const CAN_VIEW_NOTES = 'can_view_notes'
const CAN_VIEW_REPORTS = 'can_view_reports'
const CAN_VIEW_STUDIES_ALL = 'can_view_studies_all'
const CAN_VIEW_STUDIES = 'can_view_studies'

// Map of permissions to profiles.
//
const PERMISSION_PROFILE_MAP = {
    [CAN_ASSIGN_STUDIES]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_PACS_ADMIN,
        PROFILE_SUPPORT_ADMIN
    ],
    [CAN_DO_QUERY_SEARCH]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_PACS_ADMIN,
        PROFILE_SUPPORT_ADMIN
    ],
    [CAN_DOWNLOAD_REPORT]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_RECEPTION,
        PROFILE_SUPPORT_ADMIN,
        PROFILE_TECH
    ],
    [CAN_DOWNLOAD_STUDY]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_RECEPTION,
        PROFILE_SUPPORT_ADMIN,
        PROFILE_TECH,
    ],
    [CAN_EDIT_NOTES]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_NURSE,
        PROFILE_SUPPORT_ADMIN
    ],
    [CAN_EDIT_PATIENT_HISTORY]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_SUPPORT_ADMIN,
        PROFILE_TECH,
        PROFILE_QA
    ],
    [CAN_EDIT_PI]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_SUPPORT_ADMIN,
        PROFILE_TECH,
        PROFILE_QA
    ],
    [CAN_EDIT_REPORTS]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_PACS_ADMIN,
        PROFILE_SUPPORT_ADMIN,
        PROFILE_RAD1,
        PROFILE_RAD2,
        PROFILE_TRANSCRIPTION
    ],
    [CAN_LOCK_STUDY]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_PACS_ADMIN,
        PROFILE_RAD1,
        PROFILE_RAD2,
        PROFILE_SUPPORT_ADMIN,
        PROFILE_QA
    ],
    [CAN_MARK_STAT]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_PACS_ADMIN,
        PROFILE_SUPPORT_ADMIN
    ],
    [CAN_ROUTE_STUDY]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_PACS_ADMIN,
        PROFILE_RAD1,
        PROFILE_RAD2,
        PROFILE_SUPPORT_ADMIN,
        PROFILE_TECH,
        PROFILE_QA
    ],
    [CAN_SIGN_FINAL]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_PACS_ADMIN,
        PROFILE_RAD1,
        PROFILE_SUPPORT_ADMIN
    ],
    [CAN_SIGN_PRELIM]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_RAD1,
        PROFILE_RAD2,
        PROFILE_SUPPORT_ADMIN
    ],
    [CAN_SHARE_STUDY]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_PACS_ADMIN,
        PROFILE_RAD1,
        PROFILE_RAD2,
        PROFILE_SUPPORT_ADMIN,
        PROFILE_TECH,
        PROFILE_QA
    ],
    [CAN_UPDATE_STUDY]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_PACS_ADMIN,
        PROFILE_QA
    ],
    [CAN_UPDATE_STUDY_TENANT]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_PACS_ADMIN,
        PROFILE_SUPPORT_ADMIN
    ],
    [CAN_UPLOAD_STUDY]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_SUPPORT_ADMIN,
        PROFILE_TECH,
        PROFILE_QA
    ],
    [CAN_VIEW_IMAGES]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_PACS_ADMIN,
        PROFILE_RAD1,
        PROFILE_RAD2,
        PROFILE_SUPPORT_ADMIN,
        PROFILE_TECH,
        PROFILE_QA
    ],
    [CAN_VIEW_PATIENT_HISTORY]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_PACS_ADMIN,
        PROFILE_RAD1,
        PROFILE_RAD2,
        PROFILE_SUPPORT_ADMIN,
        PROFILE_QA
    ],
    [CAN_VIEW_NOTES]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_NURSE,
        PROFILE_PACS_ADMIN,
        PROFILE_RAD1,
        PROFILE_RAD2,
        PROFILE_SUPPORT_ADMIN,
        PROFILE_QA
    ],
    [CAN_VIEW_REPORTS]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_PACS_ADMIN,
        PROFILE_RAD1,
        PROFILE_RAD2,
        PROFILE_RECEPTION,
        PROFILE_SUPPORT_ADMIN,
        PROFILE_TECH,
        PROFILE_TRANSCRIPTION,
        PROFILE_QA
    ],
    [CAN_VIEW_STUDIES_ALL]: [
        PROFILE_MASTER_ADMIN,
        PROFILE_PACS_ADMIN,
        PROFILE_SUPPORT_ADMIN
    ],
    [CAN_VIEW_STUDIES]: [
        PROFILE_NURSE,
        PROFILE_RAD1,
        PROFILE_RAD2,
        PROFILE_RECEPTION,
        PROFILE_TECH,
        PROFILE_QA
    ]
}

// Returns true if user is master administrator.
//
function isAdmin() {
    var yorn = false;
    try {
        yorn = store.state.keycloak.tokenParsed.realm_access.roles.includes('admin')
    }
    catch(err) {
        Vue.$log.error("unable to check user's profile: "+err)
    }
    return yorn
}

function isMedDreamUser() {
    var yorn = false;
    try {
        yorn = store.state.keycloak.tokenParsed.realm_access.roles.includes('meddream_user')
    }
    catch(err) {
        Vue.$log.error("unable to check user's profile: "+err)
    }
    return yorn
}

// Returns true if user has requested permission (e.g., 'can_view_studies') for given group (e.g., 'Hospital A').
// Mapped to Keycloak realm roles via <groupID>_<permission> (e.g., 'HOSPITAL_A_can_view_studies').
//
function hasPermission(group, permission) {
    var yorn = false;
    try {
        // Is user in group and has permission role?
        //
        const groupName = group.startsWith('/') ? group : '/' + group
        var groupId = groupName.replace(/^\//, '').replace(/\//g, '___').replace(/ /g, '_').toUpperCase()
        const permissionRole = groupId + "_" + permission
        yorn = store.state.keycloak.tokenParsed.groups.includes(groupName) &&
            store.state.keycloak.tokenParsed.realm_access.roles.includes(permissionRole)
        if (!yorn) {
            // Check if user has a profile role with requested permission.
            //
            const profilesWithPermission = PERMISSION_PROFILE_MAP[permission]
            for (const profile of profilesWithPermission) {
                if (hasProfile(group, profile)) {
                    yorn = true
                    break
                }
            }
        }
    }
    catch(err) {
        Vue.$log.error("unable to check permission for group=["+group+"] permission=["+permission+"]: "+err)
    }
    return yorn
}

// Returns true if user has requested profile (e.g., 'profile_radiologist1') for given group (e.g., 'Hospital A').
// Mapped to Keycloak realm roles via <groupID>_<permission> (e.g., 'HOSPITAL_A_profile_radiologist1').
//
function hasProfile(group, profile) {
    var yorn = false;
    try {
        // Is user in group and has profile?
        //
        const groupName = group.startsWith('/') ? group : '/' + group
        var groupId = groupName.replace(/^\//, '').replace(/\//g, '___').replace(/ /g, '_').toUpperCase()
        const profileRole = groupId + "_" + profile
        yorn = store.state.keycloak.tokenParsed.groups.includes(groupName) &&
            store.state.keycloak.tokenParsed.realm_access.roles.includes(profileRole)
    }
    catch(err) {
        Vue.$log.error("unable to check profile for group=["+group+"] profile=["+profile+"]: "+err)
    }
    return yorn
}

export default {
    PROFILE_MASTER_ADMIN,
    PROFILE_SUPPORT_ADMIN,
    PROFILE_PACS_ADMIN,
    PROFILE_RAD1,
    PROFILE_RAD2,
    PROFILE_QA,
    PROFILE_TECH,
    PROFILE_RECEPTION,
    PROFILE_TRANSCRIPTION,
    PROFILE_NURSE,

    CAN_ASSIGN_STUDIES,
    CAN_DO_QUERY_SEARCH,
    CAN_DOWNLOAD_REPORT,
    CAN_DOWNLOAD_STUDY,
    CAN_EDIT_NOTES,
    CAN_EDIT_PATIENT_HISTORY,
    CAN_EDIT_PI,
    CAN_EDIT_REPORTS,
    CAN_LOCK_STUDY,
    CAN_MARK_STAT,
    CAN_ROUTE_STUDY,
    CAN_SIGN_FINAL,
    CAN_SIGN_PRELIM,
    CAN_SHARE_STUDY,
    CAN_UPDATE_STUDY,
    CAN_UPDATE_STUDY_TENANT,
    CAN_UPLOAD_STUDY,
    CAN_VIEW_IMAGES,
    CAN_VIEW_NOTES,
    CAN_VIEW_PATIENT_HISTORY,
    CAN_VIEW_REPORTS,
    CAN_VIEW_STUDIES_ALL,
    CAN_VIEW_STUDIES,

    isAdmin,
    isMedDreamUser,
    hasPermission,
    hasProfile
}